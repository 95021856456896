import {type ReactNode, useState} from 'react';

export type QA = string | (() => ReactNode);
export type FAQContent = {question: string; answer: QA};

export const FAQ = (proos: {content: FAQContent[]}) => {
  const {content} = proos;
  const [open, setOpen] = useState<number>(-1);

  return (
    <div className="">
      <h2 className="mb-8 text-center">Frequently asked questions</h2>
      <div className="mx-auto divide-y-2 divide-solid sm:w-8/12">
        {content.map((v: any, i) => (
          <div className="py-8" key={v.question}>
            <h3
              className="group flex cursor-pointer items-center text-xl font-medium leading-relaxed text-gray-500 sm:text-2xl"
              onClick={() => {
                setOpen(open === i ? -1 : i);
              }}
            >
              <span className="mb-1 mr-5 block font-semibold text-gray-800 group-hover:text-sky-500">+</span>
              <span className="text-gray-700 group-hover:text-sky-500">{v.question}</span>
            </h3>
            <div className={`${open === i ? 'block' : 'hidden'} ml-9 mt-4 text-lg leading-relaxed text-gray-600 sm:text-xl`}>
              {typeof v.answer === 'string' ? v.answer : v.answer()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
